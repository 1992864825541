@import '__importable.scss';
.container {
	width: 100%;
	max-width: 76.9rem;
	margin: 0 auto;
	padding: 1.6rem;

	> [class*='Heading'] {
		text-align: center;
		margin-bottom: 1.6rem;
	}

	@media screen and (min-width: $media-md) {
		padding-block: 3.2rem;		
	}
}