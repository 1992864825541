@import '__importable.scss';
.container {
	position: relative;
	height: 22em;
	width: 100vw;

	@media screen and (min-width: $media-sm) {
		height: 37.5em;
	}

	// Mobile phone landscape stylings
	@media only screen and (min-device-width: em-calc(667px)) and (max-device-width: em-calc(812px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(667px)) and (max-device-width: em-calc(812px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(883px)) and (max-device-width: em-calc(926px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(823px)) and (max-device-width: em-calc(851px)) and (orientation: landscape) {
		height: 15em;
	}
}

.swiper {
	height: 100%;
	flex-basis: 100%;
	z-index: 0;

	&:first-child {
		position: absolute;
		width: calc(100% - 3.2rem);
		height: 15em;
		bottom: 0;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		z-index: $zindex-1;

		@media only screen and (min-width: $media-sm) {
			height: 22em;
		}
	}

	.slide {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 100%;
			object-fit: contain;
		}
	}

	.pattern {
		position: absolute;
		inset: 0;
		height: 150%; // Allows img to be scrolled without whitespace adornment
		background-color: $gray-0;

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	>p {
		position: absolute;
		bottom: 0.4rem;
		left: 0.4rem;
		z-index: $zindex-2;
		color: $white;
	}

	@media screen and (min-width: $media-sm) {
		height: 100%;
		flex-basis: 50%;

		&:first-child {
			position: unset;
			width: unset;
			transform: unset;
			height: 100%;
			flex-basis: 50%;
			box-shadow: inset -24px 0px 48px rgba(0, 0, 0, 0.16);
		}
	}
}

.separator {
	@media screen and (min-width: $media-sm) {
		height: 100%;
		width: 1.1rem;
	}
}
