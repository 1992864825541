@import '__importable.scss';
.countdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 56rem;
	margin: 0 auto;

	header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 19.1rem; // width of highlight background image;
		margin: 0 0 1.6rem 0;
		padding: 0 0 0 0.32rem;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.7rem;
		letter-spacing: 0.02em;
		background: url('https://cdn.shopify.com/s/files/1/1147/9910/files/countdown_timer_header_highlight.svg') no-repeat;
		background-position-y: calc(100% - 2px);
		background-position-x: left;

		&:before {
			content: '';
			height: 24px;
			width: 24px;
			margin-right: 0.24rem;
			background: url('https://cdn.shopify.com/s/files/1/1147/9910/files/countdown_timer_clock_icon.svg') no-repeat;
		}
	}

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		> span {
			margin: 0 0.8rem;
			font-size: 1.6rem;
			font-weight: 300;
			line-height: 2.4rem;
			letter-spacing: 0em;
			text-align: center;
		}
	}
}