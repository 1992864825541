@import '__importable.scss';
.digitGroup {
	flex: 1;
	min-width: 6.4rem;
	padding: 0.8rem;
	background: $gray-1;
	border: 1px solid $gray-2;
	border-radius: 0.4rem;

	> div:nth-child(1) {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 2.4rem;
	}

	// TODO - extract font style
	> div:nth-child(2) {
		margin-top: 0.4rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		letter-spacing: 0em;
		text-align: center;
	}

	@media (max-width: $media-md) {
		min-width: 5.6rem;
	}
}