@import '__importable.scss';
.digit {
	@keyframes animNext {
		0% { transform: translateY(-100%); }
		100% { transform: translateY(0%); }
	}

	@keyframes animCurr {
		0% { transform: translateY(0%); }
		100% { transform: translateY(100%); }
	}

	$digitHeight: 2.4rem;
	$digitWidth: 1.4rem;

	position: relative;
	height: $digitHeight;
	width: $digitWidth;
	overflow: hidden;
	text-align: center;

	span {
		display: block;
		position: absolute;
		height: $digitHeight;
		width: $digitWidth;
		background: $gray-1;
		font-family: var(--platform);
		font-size: $digitHeight;
		font-weight: 500;
		line-height: $digitHeight;

		&:nth-child(1) {
			animation-name: animNext;
			animation-duration: 300ms;
			animation-delay: 700ms;
			animation-timing-function: cubic-bezier(0.25, 0.1, 0, 1.4);
		}

		&:nth-child(2) {
			animation-name: animCurr;
			animation-duration: 300ms;
			animation-delay: 700ms;
			animation-timing-function: cubic-bezier(0.25, 0.1, 0, 1.4);
		}
	}
}