@import '__importable.scss';
.landing-container {
	padding: 8rem;

	@media (max-width: $media-md) {
		padding: 1.6rem;
	}

	// Mobile phone landscape stylings
	@media only screen and (min-device-width: em-calc(667px)) and (max-device-width: em-calc(812px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(667px)) and (max-device-width: em-calc(812px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(883px)) and (max-device-width: em-calc(926px)) and (orientation: landscape),
	only screen and (min-device-width: em-calc(823px)) and (max-device-width: em-calc(851px)) and (orientation: landscape) {
		padding: 1.6rem;
	}
}

.landing-description {
	max-width: 100%;
	width: max-content;
	margin-block: 3.2rem;
	margin-inline: auto;
	padding: 1.6rem;
	background-color: $gray-1;
	border-radius: $radius-4;

	@media screen and (min-width: $media-md) {
		max-width: $media-md;
	}
}

.landing-top-display {
	align-items: center;
	gap: 8rem;

	@media (max-width: $media-lg) {
		gap: 6.4rem;
	}

	@media (max-width: $media-md) {
		gap: 3.2rem;
	}

	@media (max-width: $media-sm) {
		gap: 0;
		flex-direction: column;
		align-items: flex-start;
	}
}

.collection-title-image {
	margin: 1.6rem auto;

	@media (min-width: $media-sm) {
		margin: 0;
	}
}

.launch-banner {
	@media screen and (max-width: $media-sm) {
		width: 100%;
	}
}

.launch-countdown {
	width: 100%;
	padding: 1.6rem;
	background: $gray-0;
	border: 1px solid $gray-2;
	border-radius: $radius-4;
}

.landing-main-image {
	flex-direction: column;
	align-items: flex-start;

	.image-container {
		position: relative;

		.image-legal-copy {
			position: absolute;
			bottom: 1rem;
			left: 1rem;

			> p {
				color: $white;
			}
		}
	}
}

.hero-image {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.rich-text-container {
	margin-left: unset;
	margin-top: 1.6rem;
}

.legal-footer {
	margin: 1.6rem auto;
	text-align: center;
}

@media screen and (min-width: $media-sm) {
	.landing-main-image {
		margin: auto;
		align-items: center;
		flex-direction: row;
	}

	.hero-image {
		width: 40em;
		height: 44em;
	}

	.rich-text-container {
		margin-left: 4.8rem;
		max-width: $media-sm;
	}

	.legal-footer {
		margin-block: 3.2rem;
	}
}

.rich-text {
	p {
		font-size: 3rem !important;
    margin-block: 0 !important;
	}
}